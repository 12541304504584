<template>
  <b-modal
    :id="idModal"
    no-close-on-backdrop
    hide-footer
    size="lg"
    body-class="modal-height"
    style="height: 90vh"
    no-fade
  >
    <template
      slot="modal-header"
    >
      <div class="d-flex align-items-center justify-content-between w-100 px-3">
        <b-button
          variant="outline-light"
          @click="closeModal"
        >
          {{ $t('customerpopup.cancel') }}
        </b-button>
        <h5>
          {{ isView ? $t('customerpopup.view-customer') : $t('customerpopup.add-new-customer') }}
        </h5>
        <b-button
          v-if="!isView"
          variant="primary"
          @click="addCustomer"
        >
          {{ $t('customerpopup.done') }}
        </b-button>
        <b-button
          v-else-if="!isSelected"
          variant="primary"
          @click="selectCustomer"
        >
          {{ $t('customerpopup.select') }}
        </b-button>
      </div>
    </template>
    <b-card
      v-if="isView"
      class="warning-banner mb-3"
      body-class="p-0"
    >
      <div class="warning-content">
        <i class="fas fa-exclamation-circle" />
        Customer information can't be updated from this view
      </div>
    </b-card>
    <b-card
      class="general-info mb-3"
      body-class="p-0"
    >
      <div class="general-info__content d-flex flex-wrap">
        <b-form-input
          v-model="customer.first_name"
          :class="!customer.first_name & isSubmit && 'is-invalid'"
          :disabled="isView"
          :placeholder="$t('customerpopup.first-name')"
          type="email"
          autocomplete="off"
          required
        />

        <b-form-input
          v-model="customer.last_name"
          :disabled="isView"
          :placeholder="$t('customerpopup.last-name')"
          autocomplete="off"
          required
        />
        <b-form-input
          v-model="customer.phone"
          :disabled="isView"
          :placeholder="$t('customerpopup.phone-number')"
          autocomplete="off"
          required
        />
        <b-form-input
          v-model="customer.email"
          :disabled="isView"
          :placeholder="$t('customerpopup.email-id')"
          type="email"
          autocomplete="off"
          required
        />
      </div>
    </b-card>
    <b-card
      class="address-info mb-3"
      body-class="p-0"
    >
      <!-- address info -->
      <div class="flex-grow-1">
        <b-form-input
          v-model="addressDetails.address_1"
          :disabled="isView"
          :placeholder="$t('customerpopup.address-1')"
          autocomplete="off"
          required
          class="address-full-width"
        />
        <b-form-input
          v-model="addressDetails.address_2"
          :disabled="isView"
          :placeholder="$t('customerpopup.address-2')"
          autocomplete="off"
          required
          class="address-full-width"
        />
        <b-form-input
          v-model="addressDetails.city"
          :disabled="isView"
          :placeholder="$t('customerpopup.city')"
          autocomplete="off"
          required
        />
        <b-form-select
          v-if="addressDetails.country === 'US' && !isView"
          v-model="addressDetails.state"
          :options="usaState"
        >
          <template #first>
            <b-form-select-option
              :value="null"
              disabled
            >
              -- Please select a state --
            </b-form-select-option>
          </template>
        </b-form-select>
        <b-form-input
          v-else
          v-model="addressDetails.state"
          :disabled="isView"
          :placeholder="$t('customerpopup.state')"
          autocomplete="off"
          required
        />
        <b-form-input
          v-model="addressDetails.postal_code"
          :disabled="isView"
          :placeholder="$t('customerpopup.zip-code')"
          autocomplete="off"
          required
        />
        <b-form-select
          v-if="!isView"
          v-model="addressDetails.country"
          :options="countries"
          class="address-full-width"
        >
          <template #first>
            <b-form-select-option
              :value="null"
              disabled
            >
              -- Please select a country --
            </b-form-select-option>
          </template>
        </b-form-select>
        <b-form-input
          v-else
          v-model="country"
          :disabled="isView"
          :placeholder="$t('customerpopup.country')"
          autocomplete="off"
          required
          class="address-full-width"
        />
      </div>
    </b-card>
    <b-card
      class="mb-3"
      body-class="p-0"
    >
      <b-textarea
        :model="customer.notes"
        :disabled="isView"
        :placeholder="$t('customerpopup.notes')"
        rows="8"
        class="notes-textarea"
      />
    </b-card>
  </b-modal>
</template>
<script>
  // import {findAvailableCustomerLocalId, syncCustomer} from "../helpers/dbHelper";
  import orderMixin from "../mixins/order/orderMixin";
  import {mapActions,mapGetters} from 'vuex'
  import customerAddressHelper from "@/helpers/customer/customer"
  export default {
    name: 'CustomerModal',
    mixins: [orderMixin],
    props: {
      viewCustomer: {
        type: Object,
        default: () => {}
      },
      isSelected: {
        type: Boolean,
        default: false
      },
      index: {
        type: Number,
        default: null
      }
    },
    data () {
      return {
        mode: 'add',  // Default to add mode
        customer: {
          first_name: null,
          last_name: null,
          phone: null,
          email: null,
          notes: null,
        },
        addressDetails:{
          address_1: null,
          address_2: null,
          city: null,
          state: null,
          postal_code: null,
          country:null,
          default:true
        },
        isSubmit: false,
      }
    },
    computed: {
      ...mapGetters({
        countries:"customers/countries",
        usaStates:"customers/usaStates"
      }),
      isView() {
        return this.viewCustomer !== null && this.mode === 'view';
      },
      isValidForm() {
        return !!this.customer.first_name;
      },
      idModal() {
        return this.index ? 'customer-modal-' + this.index : 'customer-modal'
      },
      country(){
        if (this.addressDetails) 
          return this.addressDetails?.country && this.addressDetails?.country.length === 2 ? customerAddressHelper.deSerializeCountryValue(this.addressDetails?.country) : this.addressDetails?.country
        return ''
      }
    },
    watch: {
      viewCustomer(customer) {
        if (customer) {
          this.mode = 'view';
          this.setViewCustomer(customer);
        } else {
          this.mode = 'add';
          this.customerInit();
        }
      }
    },
    created(){
      // this.fetchCountriesAndStates()
    },
    methods: {
      ...mapActions({
        fetchCountriesAndStates : 'customers/fetchCountriesAndStates'
      }),
      async addCustomer() {
        if (!this.isValidForm)
          return this.isSubmit = true;
        const customer = this.customer;
        customer.local_id = (new Date).toISOString();
        customer.addresses = [] 
        this.addressDetails.local_id =  (new Date).toISOString();
        customer.addresses.push(this.addressDetails)
        customer.default_address = {...this.addressDetails}
        this.$store.commit('customers/save', customer)
        this.$emit('get-customers');
        this.$bvModal.hide(this.idModal);
        this.customerInit();
        this.$emit('select-customer-after-add', customer);
      },
      selectCustomer() {
        this.$bvModal.hide(this.idModal);
        this.customerInit();
        this.$emit('select-customer', this.viewCustomer);
      },
      customerInit() {
        this.customer =
          {
            first_name: null,
            last_name: null,
            phone: null,
            email: null,
            notes: null
          }
          this.addressDetails={
          address_1: null,
          address_2: null,
          city: null,
          state: null,
          postal_code: null,
          country:null,
          default:true
        }
      },
      setViewCustomer(customer) {
        if (this.isView && customer) {
          this.customerInit();
          this.$set(this, 'customer', {
            first_name: customer.first_name || null,
            last_name: customer.last_name || null,
            phone: customer.phone || null,
            email: customer.email || null,
            notes: customer.notes || null
          });
          
          if(customer.default_address) {
            this.addressDetails = { ...customer.default_address };
          } else if(customer.addresses && customer.addresses.length > 0) {
            const defaultAddress = customer.addresses.find(address => address.default === true);
            if(defaultAddress) {
              this.addressDetails = { ...defaultAddress };
            } else {
              this.addressDetails = {
                address_1: null,
                address_2: null,
                city: null,
                state: null,
                postal_code: null,
                country: null,
                default: true
              };
            }
          } else {
            this.addressDetails = {
              address_1: null,
              address_2: null,
              city: null,
              state: null,
              postal_code: null,
              country: null,
              default: true
            };
          }
        }
      },
      closeModal() {
        this.$bvModal.hide(this.idModal);
        this.customerInit();
        this.mode = 'add';
        this.$emit('close-modal');
      }
    }
  }
</script>

<style scoped type="text/css" lang="scss">
  .modal-body .modal-height {
    height: 90vh !important;
  }

  .warning-banner {
    background-color: #fff3cd;
    border: 1px solid #ffeeba;
    border-radius: 4px;
    margin-bottom: 1rem;

    .warning-content {
      padding: 0.75rem 1rem;
      color: #856404;
      font-size: 0.875rem;
      display: flex;
      align-items: center;
      gap: 0.5rem;

      i {
        color: #ffc107;
        font-size: 1rem;
      }
    }
  }

  .form-control {
    height: 3rem;
    border-radius: 0;
    border-top: initial;
    border-left: initial;
  }

  .general-info {
    padding: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid #eee;

    &__content {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;

      .form-control {
        width: 100%;
      }
    }
  }

  .address-info {
    padding: 1rem;

    .flex-grow-1 {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;

      .form-control,
      .custom-select {
        width: 100%;
      }

      .address-full-width {
        grid-column: 1 / -1;
      }
    }
  }
  
  .notes-textarea {
    min-height: 150px;
    margin-top: 1rem;
  }
</style>
