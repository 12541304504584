<template>
  <b-modal
    id="gift-voucher-modal"
    :modal-class="modalClass"
    @show="onModalOpen"
    @shown="focusInput"
  >
    <template #modal-title>
      <strong v-if="!nextStep">{{ $t("gift-card.primary-title") }}</strong>
      <h5 v-else>
        <strong>{{ $t("gift-card.secondary-title") }} {{ giftVoucherCode }}</strong>
      </h5>
    </template>
    <template #default>
      <div v-if="!nextStep && !fetchGiftCardLoader">
        <p class="mt-3">
          {{ $t("gift-card.description") }}
        </p>
        <b-input
          class="mt-3 mb-5 gift-card-input"
          v-model="giftVoucherCode"
          :state="validate"
          @keydown.enter="next()"
          placeholder="Scan or Enter a Gift Card Number"
          required
        />
      </div>
      <div
        v-else-if="nextStep && !fetchGiftCardLoader"
        class="mb-3 w-100"
      >
        <h5
          class="text-center my-4"
          :class="{'text-danger': cardCredit === 0}"
        >
          {{ $t("gift-card.current-balance") }} <strong>{{ currency }} {{ (cardCredit).toFixed(2) }}</strong>
        </h5>
        <div class="mt-5">
          <b-row class="gift-card-item-head mt-4">
            <b-col cols="2">
              {{ $t("gift-card.payment-table-header-1") }}
            </b-col>
            <b-col cols="4 px-4">
              {{ $t("gift-card.payment-table-header-2") }}
            </b-col>
            <b-col cols="3 px-4">
              {{ $t("gift-card.payment-table-header-3") }}
            </b-col>
            <b-col cols="3 text-right px-4">
              {{ $t("gift-card.payment-table-header-4") }}
            </b-col>
          </b-row>
          <div
            class="text-center my-4"
            v-if="giftVoucherPayments.length === 0"
          >
            {{ $t("gift-card.no-payment-history") }}
          </div>
          <div
            v-else
            class="gift-card-list"
          >
            <b-row
              class="gift-card-item"
              v-for="(voucher,index) in giftCardPayments"
              :key="index"
            >
              <b-col
                class="cursor"
                cols="2"
                @click="redirectToOrderList(voucher.order_id)"
              >
                <h6><strong>{{ voucher.order_id }}</strong></h6>
              </b-col>
              <b-col cols="4 px-4">
                {{ serializeDate(voucher.created_at) }}
              </b-col>
              <b-col cols="3 px-4">
                {{ serializeAmount(voucher.amount_paid, voucher.transaction_type) }}
              </b-col>
              <b-col
                cols="3 text-right px-4"
                v-if="voucher.transaction_balance>=0"
              >
                {{ serializeAmount(voucher.transaction_balance) }}
              </b-col>
            </b-row> 
          </div>
        </div>
      </div>
      <div
        class="text-center"
        v-else
      >
        Processing ...
      </div>
    </template>

    <template
      #modal-footer
      class="d-flex justify-content-between"
      v-if="!fetchGiftCardLoader"
    >
      <b-btn
        class="mr-auto ml-4"
        variant="outline-primary"
        @click="closeModal()"
      >
        Cancel
      </b-btn>
      <b-btn
        v-if="!nextStep"
        class="ml-auto mr-4"
        variant="outline-primary"
        @click.stop="next()"
      >
        Next
      </b-btn>
      <div v-else-if="giftCardCheckout">
        <b-btn
          v-if="cardCredit <= 0"
          class="ml-auto mr-4"
          variant="outline-danger"
        >
          INSUFFICIENT BALANCE
        </b-btn>
        <b-btn
          v-else
          class="ml-auto mr-4"
          variant="outline-primary"
          @click.stop="payBills()"
        >
          {{ isGiftCardRefund ? 'Reimburse' : 'Pay' }} {{ (amountPayable).toFixed(2) }}
        </b-btn>
      </div>
      <b-btn
        v-else
        class="ml-auto mr-4"
        variant="outline-primary"
        @click.stop="addFunds()"
      >
        Add Funds
      </b-btn>
    </template>
  </b-modal>
</template>
<script>
import {mapGetters, mapActions} from 'vuex'
import moment from 'moment'
import { GIFT_CARD_TRANSACTION_TYPE } from "@/helpers/order/transaction/types";
export default{
    data :()=>({
        openModal:true,
        giftVoucherCode:'',
        validate:null,
        nextStep:false,
        transactionBalance:0,
        voucherCodeRegex:/^\d+(?:-\d+)*$/,
        fetchGiftCardLoader:false,
        // balance:0
    }),
    props:{
      giftCardCheckout:{
        type: Boolean,
        default: false
      },
      checkoutAmount:{
        type: Number,
        default: 0
      }
    },
    computed:{
        ...mapGetters({
            giftVoucherPayments:'giftVoucher/giftVoucherPayments',
            activeGiftCard:'giftVoucher/activeGiftCard',
            giftCards:'giftVoucher/giftCards',
            store:'config/store',
            terminal:'config/terminal',
            isActiveOrder: 'orders/isActiveOrder',
            currency: "config/currency",
            refundedGiftCards: "giftVoucher/refundedGiftCards"
        }),
        giftCardPayments(){
           return this.giftVoucherPayments
        },
        cardCredit(){
          return this.activeGiftCard.credit
        },
        amountPayable(){
          if(this.cardCredit > this.checkoutAmount)
            return this.checkoutAmount  
          else 
            return this.cardCredit
        },
        modalClass(){
          return this.nextStep ? 'gift-card-popup' : 'gift-card-popup gift-card-number-modal' 
        },
        isGiftCardRefund(){
          return this.refundedGiftCards.length > 0 ?  true : false
        }
    },
    methods:{
        ...mapActions({
            setGiftCardPayments:'giftVoucher/setGiftCardPayments',
            fetchGiftCardPayments:'giftVoucher/fetchGiftCardPayments',
            fetchGiftCard:'giftVoucher/fetchGiftCard',
            setGiftCard:'giftVoucher/setGiftCard',
            createNewGiftCard:'giftVoucher/createNewGiftCard',
            setGiftCardCheckout:'giftVoucher/setGiftCardCheckout',
            setRefundGiftCardIds:'giftVoucher/setRefundGiftCardIds',
            toggleGiftCardPaymentInitiater:'giftVoucher/toggleGiftCardPaymentInitiater',
            setSelectedOrder:'orders/setSelectedOrder'
        }),
        focusInput() {
            // Focus the input after modal is fully shown
            const input = document.querySelector('.gift-card-input')
            if (input) {
                input.focus()
            }
        },
        closeModal(){
            this.nextStep = false
            this.giftVoucherCode = null
            this.validate = null
            if(this.giftCardCheckout){
              this.setGiftCardCheckout(false)
              this.toggleGiftCardPaymentInitiater()
            }
            this.setSelectedOrder(null)
            this.$bvModal.hide('gift-voucher-modal')
        },
        async next(){

          if(this.voucherCodeRegex.test(this.giftVoucherCode)){
          const isValid = await this.fetchGiftCard({'number':this.giftVoucherCode, "param":"card"})
          // const isExpired = this.giftCards.find(card=> card.gift_card_number === this.giftVoucherCode && (card.is_expired === true || card.credit < 0.01))
          // if(!this.giftVoucherCode)
          //   {
          //       this.validate = false
          //       return
          //   } 
          //   else 
            if(!isValid && !this.giftCardCheckout){
              const payload = {
                gift_card_number: this.giftVoucherCode,
                store: this.store.id
              }
              await this.createNewGiftCard(payload)
              await this.setGiftCard(this.giftVoucherCode)
              this.transactionBalance = this.activeGiftCard.credit
              this.nextStep=true
              this.validate=null
              return
            }
            else if(isValid){
              await this.setGiftCard(this.giftVoucherCode)
              this.transactionBalance = this.activeGiftCard.credit
              this.nextStep=true
              this.validate=null
            }
          }
          else{
            this.validate = false
            return
          }
        },
        addFunds(){
          this.$bvModal.show('gift-card-modal')
          this.$bvModal.hide('gift-voucher-modal')
        },
        serializeAmount(amount,transaction_type=null){
              if(amount < 0 || transaction_type===GIFT_CARD_TRANSACTION_TYPE.CREDIT){
                  return `+${this.currency}${(amount).toFixed(2)}`
              }
              else if(transaction_type===GIFT_CARD_TRANSACTION_TYPE.DEBIT){
                  return `-${this.currency}${(amount).toFixed(2)}`
              }
              else{
                return `${this.currency}${(amount).toFixed(2)}`
              }
        },
        serializeDate(date){
          return moment(date).format('DD MMMM yy, hh:mm A')
        },
        payBills(){
          const giftCardDetails = {
            "gift_card_number":this.activeGiftCard.id,
            "amount_paid":parseFloat(Math.abs(this.amountPayable)),
            "transaction_balance": this.isGiftCardRefund ? parseFloat((this.cardCredit + Math.abs(this.amountPayable)).toFixed(2)) : parseFloat((this.cardCredit - Math.abs(this.amountPayable)).toFixed(2)) ,
            "transaction_type": this.isGiftCardRefund ? GIFT_CARD_TRANSACTION_TYPE.CREDIT : GIFT_CARD_TRANSACTION_TYPE.DEBIT,
            "terminal":this.terminal.id,
            "order":null,
            "is_applied":true,
            "local_id":null,
          }
          this.$emit('payFromGiftCard',giftCardDetails)
          this.nextStep = false
          this.giftVoucherCode = null
          this.$bvModal.hide('gift-voucher-modal')
        },
        redirectToOrderList(orderId){
          this.setSelectedOrder(orderId)
          if(this.isActiveOrder){
            this.$bvModal.show('leaveCurrentOrderModal')
            this.$bvModal.hide('gift-voucher-modal')
          }
          else{
            this.$bvModal.hide('gift-voucher-modal')
            this.$router.push({name:"OrdersList"})
          }
        },
        async onModalOpen(){
          if(this.isGiftCardRefund){
            this.fetchGiftCardLoader = true
            await this.fetchGiftCard({'number':this.refundedGiftCards[0], "param":"id"})
            await this.setGiftCard(this.refundedGiftCards[0])
            this.transactionBalance = this.activeGiftCard.credit
            this.fetchGiftCardLoader = false
            this.nextStep=true
          }else{
            this.setRefundGiftCardIds([])
          }
        }
    }
}
</script>
<style scoped>
.cursor{
  cursor:pointer;
}

</style>