<template>
  <div>
    <b-modal
      id="select-customer-modal"
      no-close-on-backdrop
      hide-footer
      size="xl"
      body-class="p-0 customer-modal-wrapper"
      style="height: 90vh"
      no-fade
    >
      <template
        slot="modal-header"
        slot-scope="{ close }"
      >
        <div class="d-md-none d-flex align-items-center justify-content-between w-100 px-3">
          <b-button
            variant="outline-light"
            @click="close()"
          >
            {{ $t("customerlistpopup.cancel") }}
          </b-button>
          <h5 class="mb-0">
            {{ $t("customerlistpopup.select-customer") }}
          </h5>
          <svg
            class="icon icon-icon-plus"
            @click="showAddCustomerModal"
          >
            <use xlink:href="#icon-icon-plus" />
          </svg>
        </div>
        <div class="d-none d-md-flex align-items-center justify-content-between w-100 px-3">
          <div>
            <b-button
              variant="outline-light"
              @click="close()"
            >
              {{ $t("customerlistpopup.cancel") }}
            </b-button>
          </div>
          <h5 class="mb-0">
            {{ $t("customerlistpopup.select-customer") }}
          </h5>
          <div>
            <b-button
              variant="primary"
              @click="showAddCustomerModal"
            >
              {{ $t("customerlistpopup.add") }}
            </b-button>
          </div>
        </div>
      </template>
      <div class="search">
        <label 
          class="search__icon-label" 
          for="search-customer-input"
        >
          <svg class="icon icon-icon-search">
            <use xlink:href="#icon-icon-search" />
          </svg>
        </label>
        <b-input
          id="search-customer-input"
          ref="searchField"
          v-model="search"
          :placeholder="$t('searchbar.search-item')"
          class="search__customer-input"
          autocomplete="off"
        />
      </div>
      <div class="table-header">
        <div class="text-left">
          <span>Name</span>
        </div>
        <div class="text-right">
          <span class="phone-number">Phone Number</span>
          <span class="actions-space" />
        </div>
      </div>
      <div
        class="content"
        @scroll="handleScroll($event)"
      >
        <div
          v-for="customer in filteredCustomers"
          :key="customer.id"
          class="orders-bottom-item"
        >
          <div class="text-left">
            <span>
              {{ customerFullName(customer) }}
            </span>
          </div>
          <div class="text-right">
            <span class="px-2">
              {{ customer.phone }}
            </span>
            <div class="button-group">
              <div class="d-md-none d-flex">
                <svg
                  class="margin-r icon icon-icon-view"
                  @click="showViewCustomerModal(customer)"
                >
                  <use xlink:href="#icon-icon-view" />
                </svg>
                <template v-if="selectedCustomer && customer.id === selectedCustomer.id">
                  <svg
                    class="margin-r icon icon-icon-fill-check"
                    @click="removeCustomer"
                  >
                    <use xlink:href="#icon-icon-fill-check" />
                  </svg>
                  <svg
                    class="icon icon-icon-edit"
                    @click="openAddNewAddressModal(customer)"
                  >
                    <use xlink:href="#icon-icon-edit" />
                  </svg>
                </template>
                <template v-else>
                  <svg
                    class="margin-r icon icon-icon-unfill-check"
                    @click="selectCustomer(customer)"
                  >
                    <use xlink:href="#icon-icon-unfill-check" />
                  </svg>
                  <svg
                    class="icon icon-icon-edit"
                    @click="openAddNewAddressModal(customer)"
                  >
                    <use xlink:href="#icon-icon-edit" />
                  </svg>
                </template>
              </div>
              <div class="d-none d-md-block">
                <b-button
                  variant="outline-light"
                  class="action-btn"
                  @click="showViewCustomerModal(customer)"
                >
                  {{ $t("customerlistpopup.view-customer") }}
                </b-button>
                <template v-if="selectedCustomer && customer.id === selectedCustomer.id">
                  <b-button
                    variant="danger"
                    class="action-btn"
                    @click="removeCustomer"
                  >
                    {{ $t("customerlistpopup.remove-customer") }}
                  </b-button>
                  <b-button
                    variant="outline-light"
                    class="action-btn"
                    @click="openAddNewAddressModal(customer)"
                  >
                    Update Address
                  </b-button>
                </template>
                <template v-else>
                  <b-button
                    variant="outline-light"
                    class="action-btn"
                    @click="selectCustomer(customer)"
                  >
                    {{ $t("customerlistpopup.apply-customer") }}
                  </b-button>
                  <b-button
                    variant="outline-light"
                    class="action-btn"
                    @click="openAddNewAddressModal(customer)"
                  >
                    Update Address
                  </b-button>
                </template>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="search.length !== 0"
          class="orders-bottom-item orders-bottom-item__add-new"
          @click="showAddCustomerModal"
        >
          <span>
            Add New Customer
          </span>
        </div>
        <div
          v-if="isLoading"
          class="text-center py-3"
        >
          <b-spinner
            variant="primary"
            label="Loading..."
          />
        </div>
      </div>
      <CustomerModal
        :view-customer="viewCustomer"
        :is-selected="isSelectedViewCustomer"
        get-customers="getCustomers"
        @select-customer-after-add="selectCustomer"
        @select-customer="selectCustomer"
        @close-modal="clearViewCustomer"
      />
    </b-modal>
    <AddNewAddressModal
      :open="showUpdateAddAddressModal"
      :customer="updateAddressCustomer"
      @closeModal="closeAddNewAddressModal"
    />
  </div>
</template>

<script>
import viewCustomerModalMixin from "../mixins/modals/viewCustomerModalMixin";
import DashboardService from "../services/dashboard-service";
import {debounce} from 'lodash';

export default {
  name: "SelectCustomerModal",
  components: {
    CustomerModal: () => import("./CustomerModal.vue"),
    AddNewAddressModal: () => import("../modals/Address/AddNewAddressModal.vue")
  },
  mixins: [viewCustomerModalMixin],
  props: ["orderCustomer"],
  data() {
    return {
      search: "",
      selectedCustomer: null,
      viewCustomer: null,
      showUpdateAddAddressModal: false,
      updateAddressCustomer: null,
      page: 1,
      isLoading: false,
      hasMore: true,
      nextPage: null
    };
  },
  computed: {
    customers() {
      return this.$store.getters["customers/getAllCustomers"];
    },
    isSelectedViewCustomer() {
      if (!this.viewCustomer || !this.selectedCustomer) return false;
      return this.viewCustomer.id === this.selectedCustomer.id;
    },
    filteredCustomers() {
      let filtered = [...this.customers]; // Create a copy of the array
      
      // Remove selected customer from the list if it exists
      if (this.selectedCustomer) {
        filtered = filtered.filter(c => c.id !== this.selectedCustomer.id);
      }
      
      // Apply search filter if search term exists
      if (this.search) {
        const searchLower = this.search.toLowerCase();
        filtered = filtered.filter(customer => {
          const fullName = this.customerFullName(customer).toLowerCase();
          const phone = customer.phone ? customer.phone.toLowerCase() : '';
          return fullName.includes(searchLower) || phone.includes(searchLower);
        });
      }
      
      // Add selected customer at the beginning if it exists
      if (this.selectedCustomer) {
        filtered.unshift(this.selectedCustomer);
      }
      
      return filtered;
    }
  },
  watch: {
    orderCustomer(customer) {
      this.selectedCustomer = customer;
    },
    search() {
      this.debouncedSearch();
    }
  },
  mounted() {
    this.loadAllCustomers();
  },
  methods: {
    debouncedSearch: debounce(function() {
      this.loadCustomers();
    }, 500),
    async loadAllCustomers() {
      this.isLoading = true;
      try {
        let currentPage = 1;
        let hasMore = true;

        // Reset store on initial load
        this.$store.commit('customers/updateCustomers', {
          results: [],
          page: 1
        });

        while (hasMore) {
          const response = await DashboardService.getCustomers(currentPage);
          
          if (response.results && response.results.length > 0) {
            // Update store with new customers
            this.$store.commit('customers/updateCustomers', {
              results: response.results,
              page: currentPage
            });
            
            hasMore = !!response.next;
            currentPage++;
          } else {
            hasMore = false;
          }
        }
      } catch (error) {
        console.error('Error loading customers:', error);
      } finally {
        this.isLoading = false;
      }
    },
    async loadCustomers() {
      if (this.isLoading) return;
      
      try {
        this.isLoading = true;
        const response = await DashboardService.getCustomers(this.page);
        
        if (response.results && response.results.length > 0) {
          // Update store with new customers
          this.$store.commit('customers/updateCustomers', {
            results: response.results,
            page: this.page
          });
        }
      } catch (error) {
        console.error('Error loading customers:', error);
        this.$store.dispatch("errorModal/displayModalError", {
          modalId: "customer-error",
          errorBody: {
            title: "Error Loading Customers",
            info: "Failed to load customers. Please try again."
          }
        });
      } finally {
        this.isLoading = false;
      }
    },
    handleScroll(e) {
      const element = e.target;
      if (element.scrollHeight - element.scrollTop <= element.clientHeight + 100) {
        // User has scrolled near the bottom (within 100px)
        this.loadCustomers();
      }
    },
    showAddCustomerModal() {
      this.$bvModal.show("customer-modal");
    },
    customerFullName(customer) {
      return `${customer.first_name}${customer.last_name ? (" " + customer.last_name) : ""}`;
    },
    selectCustomer(customer) {
      this.clearViewCustomer();
      this.$emit("select-customer", customer);
      this.$bvModal.hide("select-customer-modal");
    },
    removeCustomer(customer) {
      this.$emit("remove-customer", customer);
    },
    openAddNewAddressModal(customer=null){
      if(customer)
      {
      
        this.updateAddressCustomer = customer
        this.showUpdateAddAddressModal = true
      }

    },
    closeAddNewAddressModal(){
      this.showUpdateAddAddressModal = false
    }
  }
};
</script>

<style scoped lang="scss">
.customer-modal-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.margin-r {
  margin-right: 5px;
}

.modal-body {
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.content {
  flex: 1;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.search {
  position: relative;
  background: white;
}

.search__customer-input {
  background: none;
  border: none;
  padding-left: 40px;
  height: 3.5rem;
}

.search__icon-label {
  position: absolute;
  color: grey;
  left: 15px;
  top: 15px;
  margin: 0;
}

.orders-bottom-item {
  background: white;
  display: flex;
  align-items: center;
  padding: 1rem;
  border-bottom: 1px solid #eee;

  &__add-new {
    color: #2196F3;
  }

  .text-left {
    flex: 1;
    min-width: 0;
    word-break: break-word;
    padding-left: 1rem;
  }

  .text-right {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    .px-2 {
      width: 120px;
      margin-right: 1rem;
    }

    .button-group {
      display: flex;
      gap: 0.5rem;
      width: auto;
      min-width: 380px;

      .action-btn {
        flex: 1;
        white-space: nowrap;
        min-width: 120px;
        margin: 0 4px;
      }

      @media (max-width: 767px) {
        min-width: auto;
        width: 100px;
        flex-direction: column;
        
        .action-btn {
          min-width: auto;
        }
      }
    }

    @media (max-width: 767px) {
      .px-2 {
        width: auto;
        min-width: auto;
        margin-right: 0.5rem;
      }
    }
  }
}

.table-header {
  background: white;
  padding: 1rem;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 0.75rem;
  color: #666;

  .text-left {
    flex: 1;
    padding-left: 1rem;
  }

  .text-right {
    display: flex;
    align-items: center;

    .phone-number {
      width: 120px;
      margin-right: 3rem;
    }

    @media (max-width: 767px) {
      .phone-number {
        margin-right: 1rem;
      }
    }

    .actions-space {
      min-width: 380px;
      @media (max-width: 767px) {
        width: 100px;
        min-width: auto;
      }
    }
  }
}

.button-group {
  display: flex;
  gap: 8px;
  justify-content: center;
  margin-top: 8px;
}

.action-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  min-width: 120px;
  padding: 8px 16px;
  font-size: 0.875rem;
  line-height: 1.125rem;
  border-radius: 3px;
  transition: all 0.2s ease-in-out;

  i {
    font-size: 0.875rem;
  }

  &:hover {
    opacity: 0.85;
  }

  &:focus {
    box-shadow: none !important;
  }
}
</style>
