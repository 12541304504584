export default {
  data () {
    return {
      viewCustomer: null
    }
  },
  methods: {
    showViewCustomerModal(customer, index) {
      this.viewCustomer = { ...customer }; // Create a deep copy of the customer object
      this.$bvModal.show('customer-modal'+(index ? '-' + index : ''));
    },
    clearViewCustomer() {
      this.viewCustomer = null
    }
  }
};
