import { render, staticRenderFns } from "./CustomerModal.vue?vue&type=template&id=15559e8a&scoped=true&"
import script from "./CustomerModal.vue?vue&type=script&lang=js&"
export * from "./CustomerModal.vue?vue&type=script&lang=js&"
import style0 from "./CustomerModal.vue?vue&type=style&index=0&id=15559e8a&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "15559e8a",
  null
  
)

export default component.exports