import { fetchWithTimeout } from '../utils';

const parseDejavooConfig = (urlField) => {
  const [test, tpn, authkey] = urlField.split(',').map(item => item.trim());
  const baseUrl = test.toLowerCase() === 'test' 
    ? 'https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-e88058ad-99ed-4726-ad6f-5537349a4c03/default/dejavoo-proxy/v2/Payment'
    : 'https://faas-nyc1-2ef2e6cc.doserverless.co/api/v1/web/fn-e88058ad-99ed-4726-ad6f-5537349a4c03/default/dejavoo-proxy-prod/V2/Payment';
  
  return {
    baseUrl,
    tpn,
    authkey
  };
};

export const makeDejavooPayment = async (urlField, amount, referenceId, invoiceNumber, transaction_type) => {
  try {
    const { baseUrl, tpn, authkey } = parseDejavooConfig(urlField);
    const headers = {
      'Content-Type': 'application/json'
    };

    const payload = {
      body: {
        Amount: amount.toString(),
        TipAmount: "0.00",
        ExternalReceipt: "",
        PaymentType: transaction_type,
        ReferenceId: referenceId,
        PrintReceipt: "No",
        GetReceipt: "No",
        MerchantNumber: "",
        InvoiceNumber: invoiceNumber,
        CaptureSignature: false,
        GetExtendedData: true,
        CallbackInfo: {
          Url: ""
        },
        Tpn: tpn,
        Authkey: authkey,
        SPInProxyTimeout: "60",
        CustomFields: {}
      }
    };

    const response = await fetchWithTimeout(60000, fetch(`${baseUrl}/Sale`, {
      method: 'POST',
      headers,
      body: JSON.stringify(payload)
    }));

    const text = await response.text();

    try {
      const data = text ? JSON.parse(text) : {};
      
      // Check for specific status code "0000"
      if (!data.GeneralResponse || data.GeneralResponse.StatusCode !== "0000") {
        const errorMessage = data.GeneralResponse?.DetailedMessage || data.GeneralResponse?.Message || 'Payment not approved';
        throw new Error(errorMessage);
      }
      
      if (data.Amounts) {
        return data;
      } else {
        console.log('Waiting for payment completion...');
        return data;
      }
    } catch (parseError) {
      console.error('Error parsing response:', parseError);
      throw new Error(`Error processing payment response. Status: ${response.status}`);
    }
  } catch (error) {
    console.error('Error in Dejavoo payment:', error);
    throw error;
  }
};

export const makeDejavooRefund = async (urlField, amount, referenceId, invoiceNumber, transaction_type) => {
  try {
    const { baseUrl, tpn, authkey } = parseDejavooConfig(urlField);
    const headers = {
      'Content-Type': 'application/json'
    };

    const payload = {
      body: {
        Amount: Math.abs(amount).toString(),
        PaymentType: transaction_type,
        ReferenceId: referenceId,
        PrintReceipt: "No",
        GetReceipt: "No",
        MerchantNumber: null,
        InvoiceNumber: invoiceNumber,
        CaptureSignature: false,
        GetExtendedData: true,
        CallbackInfo: {
          Url: ""
        },
        Tpn: tpn,
        Authkey: authkey,
        SPInProxyTimeout: null,
        CustomFields: {}
      }
    };

    const response = await fetchWithTimeout(60000, fetch(`${baseUrl}/Return`, {
      method: 'POST',
      headers,
      body: JSON.stringify(payload)
    }));

    const text = await response.text();

    try {
      const data = text ? JSON.parse(text) : {};
      
      // Check for specific status code "0000"
      if (!data.GeneralResponse || data.GeneralResponse.StatusCode !== "0000") {
        const errorMessage = data.GeneralResponse?.DetailedMessage || data.GeneralResponse?.Message || 'Refund not approved';
        throw new Error(errorMessage);
      }
      
      return data;
    } catch (parseError) {
      console.error('Error parsing response:', parseError);
      throw new Error(`Error processing refund response. Status: ${response.status}`);
    }
  } catch (error) {
    console.error('Error in Dejavoo refund:', error);
    throw error;
  }
};
